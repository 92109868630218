@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,700,800&display=swap");
@import url("https://fonts.googleapis.com/css?family=Alegreya&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";
/************* Global *************/
:root {
  /* Burger */
  --w: 40px;
  --color: #fff;
  --trs: 0.4s;

  /* Shadow */
  --shadow1: 0 3px 15px 2px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

a {
  text-decoration: none;
  color: #fff;
}

body {
  display: grid;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
}

.hide {
  display: none;
}

.stars {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 6;
  pointer-events: none;
}

/************* Container *************/
.container {
  width: 100%;
  height: 100vh;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.c-grid {
  display: grid;
  grid-template-columns: 2fr 3fr;
}

.c-grid-home {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
}

/************* Box *************/
.box {
  background: var(--box);
  box-shadow: 0 3px 15px 2px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: calc(100vh - 14rem);
  max-width: calc(100% - 14rem);
  margin: 0 auto;
}

/************* Navbar *************/
.brand {
  width: 50px;
  height: 50px;
  text-align: center;
}

.active {
  /* background: linear-gradient(to bottom, #d06338 0%, #ff547f 100%); */
  background: linear-gradient(to bottom, var(--gold) 0%, var(--gold) 100%);
  background-position: 0px 55%;
  background-repeat: repeat-x;
  background-size: 100% 4px !important;
  color: #fff;
  text-decoration: none;
  transition: background-size 0.3s;
}

.active:hover {
  background-size: 4px 50px !important;
}

/************* Home -> Arrow *************/
.arrow {
  margin-top: 0;
}

.the-arrow {
  width: 64px;
  transition: all 0.2s;
}
.the-arrow.-left {
  position: absolute;
  top: 60%;
  left: 0;
}
.the-arrow.-left > .shaft {
  width: 0;
  background-color: #999;
}
.the-arrow.-left > .shaft:before,
.the-arrow.-left > .shaft:after {
  width: 0;
  background-color: #999;
}
.the-arrow.-left > .shaft:before {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}
.the-arrow.-left > .shaft:after {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}
.the-arrow.-right {
  top: 3px;
}
.the-arrow.-right > .shaft {
  width: 64px;
  transition-delay: 0.2s;
}
.the-arrow.-right > .shaft:before,
.the-arrow.-right > .shaft:after {
  width: 8px;
  transition-delay: 0.3s;
  transition: all 0.5s;
}
.the-arrow.-right > .shaft:before {
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
}
.the-arrow.-right > .shaft:after {
  -webkit-transform: rotate(-40deg);
  transform: rotate(-40deg);
}
.the-arrow > .shaft {
  background-color: #999;
  display: block;
  height: 1px;
  position: relative;
  transition: all 0.2s;
  transition-delay: 0;
  will-change: transform;
}
.the-arrow > .shaft:before,
.the-arrow > .shaft:after {
  background-color: #999;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.2s;
  transition-delay: 0;
}
.the-arrow > .shaft:before {
  -webkit-transform-origin: top right;
  transform-origin: top right;
}
.the-arrow > .shaft:after {
  -webkit-transform-origin: bottom right;
  transform-origin: bottom right;
}

/************* Scrollbar *************/
::-webkit-scrollbar {
  width: 0;
}
::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border: 4px solid transparent;
  border-radius: 7px;
  background-color: var(--primary);
}
::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}

/************* Burger Icon *************/
#hamburger {
  display: none;
}
[for="hamburger"] {
  font-size: 0;
}
#dburger {
  width: var(--w);
  height: var(--w);
  transform: rotate(-90deg);
  cursor: pointer;
}
line {
  stroke: var(--color);
  stroke-width: calc(var(--w) / 12.5);
  transform-origin: center;
  transition: var(--trs);
}
line:nth-of-type(1) {
  transform: translateX(calc(var(--w) / -20));
}
line:nth-of-type(2) {
  transform: translateX(calc(var(--w) / 5));
}
#hamburger:checked ~ [for="hamburger"] line:nth-of-type(1) {
  transform: translateX(0) rotate(-45deg);
}

#hamburger:checked ~ [for="hamburger"] line:nth-of-type(2) {
  transform: translateX(0) rotate(45deg);
}
.ham-circle {
  fill: transparent;
  stroke: var(--color);
  stroke-width: calc(var(--w) / 25);
  stroke-dasharray: 303px;
  stroke-dashoffset: 303px;
  transition: var(--trs);
}
.ham-circle:hover {
  stroke-dashoffset: 0;
}
