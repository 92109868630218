@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,700,800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Alegreya&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/************* Global *************/
:root {
  /* Burger */
  --w: 40px;
  --color: #fff;
  --trs: 0.4s;

  /* Shadow */
  --shadow1: 0 3px 15px 2px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

a {
  text-decoration: none;
  color: #fff;
}

body {
  display: grid;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
}

.hide {
  display: none;
}

.stars {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 6;
  pointer-events: none;
}

/************* Container *************/
.container {
  width: 100%;
  height: 100vh;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.c-grid {
  display: grid;
  grid-template-columns: 2fr 3fr;
}

.c-grid-home {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
}

/************* Box *************/
.box {
  background: var(--box);
  box-shadow: 0 3px 15px 2px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  width: 100%;
  height: calc(100vh - 14rem);
  max-width: calc(100% - 14rem);
  margin: 0 auto;
}

/************* Navbar *************/
.brand {
  width: 50px;
  height: 50px;
  text-align: center;
}

.active {
  /* background: linear-gradient(to bottom, #d06338 0%, #ff547f 100%); */
  background: -webkit-gradient(linear, left top, left bottom, from(var(--gold)), to(var(--gold)));
  background: -webkit-linear-gradient(top, var(--gold) 0%, var(--gold) 100%);
  background: linear-gradient(to bottom, var(--gold) 0%, var(--gold) 100%);
  background-position: 0px 55%;
  background-repeat: repeat-x;
  background-size: 100% 4px !important;
  color: #fff;
  text-decoration: none;
  -webkit-transition: background-size 0.3s;
  transition: background-size 0.3s;
}

.active:hover {
  background-size: 4px 50px !important;
}

/************* Home -> Arrow *************/
.arrow {
  margin-top: 0;
}

.the-arrow {
  width: 64px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.the-arrow.-left {
  position: absolute;
  top: 60%;
  left: 0;
}
.the-arrow.-left > .shaft {
  width: 0;
  background-color: #999;
}
.the-arrow.-left > .shaft:before,
.the-arrow.-left > .shaft:after {
  width: 0;
  background-color: #999;
}
.the-arrow.-left > .shaft:before {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}
.the-arrow.-left > .shaft:after {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}
.the-arrow.-right {
  top: 3px;
}
.the-arrow.-right > .shaft {
  width: 64px;
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
}
.the-arrow.-right > .shaft:before,
.the-arrow.-right > .shaft:after {
  width: 8px;
  -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.the-arrow.-right > .shaft:before {
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
}
.the-arrow.-right > .shaft:after {
  -webkit-transform: rotate(-40deg);
  transform: rotate(-40deg);
}
.the-arrow > .shaft {
  background-color: #999;
  display: block;
  height: 1px;
  position: relative;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0;
          transition-delay: 0;
  will-change: transform;
}
.the-arrow > .shaft:before,
.the-arrow > .shaft:after {
  background-color: #999;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0;
          transition-delay: 0;
}
.the-arrow > .shaft:before {
  -webkit-transform-origin: top right;
  transform-origin: top right;
}
.the-arrow > .shaft:after {
  -webkit-transform-origin: bottom right;
  transform-origin: bottom right;
}

/************* Scrollbar *************/
::-webkit-scrollbar {
  width: 0;
}
::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border: 4px solid transparent;
  border-radius: 7px;
  background-color: var(--primary);
}
::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}

/************* Burger Icon *************/
#hamburger {
  display: none;
}
[for="hamburger"] {
  font-size: 0;
}
#dburger {
  width: 40px;
  width: var(--w);
  height: 40px;
  height: var(--w);
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  cursor: pointer;
}
line {
  stroke: #fff;
  stroke: var(--color);
  stroke-width: calc(40px / 12.5);
  stroke-width: calc(var(--w) / 12.5);
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-transition: var(--trs);
  transition: var(--trs);
}
line:nth-of-type(1) {
  -webkit-transform: translateX(calc(40px / -20));
          transform: translateX(calc(40px / -20));
  -webkit-transform: translateX(calc(var(--w) / -20));
          transform: translateX(calc(var(--w) / -20));
}
line:nth-of-type(2) {
  -webkit-transform: translateX(calc(40px / 5));
          transform: translateX(calc(40px / 5));
  -webkit-transform: translateX(calc(var(--w) / 5));
          transform: translateX(calc(var(--w) / 5));
}
#hamburger:checked ~ [for="hamburger"] line:nth-of-type(1) {
  -webkit-transform: translateX(0) rotate(-45deg);
          transform: translateX(0) rotate(-45deg);
}

#hamburger:checked ~ [for="hamburger"] line:nth-of-type(2) {
  -webkit-transform: translateX(0) rotate(45deg);
          transform: translateX(0) rotate(45deg);
}
.ham-circle {
  fill: transparent;
  stroke: #fff;
  stroke: var(--color);
  stroke-width: calc(40px / 25);
  stroke-width: calc(var(--w) / 25);
  stroke-dasharray: 303px;
  stroke-dashoffset: 303px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  -webkit-transition: var(--trs);
  transition: var(--trs);
}
.ham-circle:hover {
  stroke-dashoffset: 0;
}

/************* Marquee *************/
.marquee-container {
  width: 100%;
  position: relative;
}

/* .marquee-container::before {
    content: '';
    border-top: 8px solid var(--primary);
    width: 40%;
    position: absolute;
    left: 0;
    top: -50px;
  } */

#marquee {
  /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
  width: 700px;
  -webkit-animation: fill 0.5s ease forwards 2.5s;
          animation: fill 0.5s ease forwards 2.5s;
}

#marquee path:nth-child(1) {
  stroke-dasharray: 443px;
  stroke-dashoffset: 443px;
  -webkit-animation: marquee-anim 2s ease forwards 0.5s;
          animation: marquee-anim 2s ease forwards 0.5s;
}

#marquee path:nth-child(2) {
  stroke-dasharray: 228.42px;
  stroke-dashoffset: 228.42px;
  -webkit-animation: marquee-anim 2s ease forwards 0.8s;
          animation: marquee-anim 2s ease forwards 0.8s;
}

#marquee path:nth-child(3) {
  stroke-dasharray: 98.15px;
  stroke-dashoffset: 98.15px;
  -webkit-animation: marquee-anim 2s ease forwards 0.1s;
          animation: marquee-anim 2s ease forwards 0.1s;
}

#marquee path:nth-child(4) {
  stroke-dasharray: 390.74px;
  stroke-dashoffset: 390.74px;
  -webkit-animation: marquee-anim 2s ease forwards 0.13s;
          animation: marquee-anim 2s ease forwards 0.13s;
}

#marquee path:nth-child(5) {
  stroke-dasharray: 387.47px;
  stroke-dashoffset: 387.47px;
  -webkit-animation: marquee-anim 2s ease forwards 0.16s;
          animation: marquee-anim 2s ease forwards 0.16s;
}

#marquee path:nth-child(6) {
  stroke-dasharray: 215px;
  stroke-dashoffset: 215px;
  -webkit-animation: marquee-anim 2s ease forwards 0.19s;
          animation: marquee-anim 2s ease forwards 0.19s;
}

#marquee path:nth-child(7) {
  stroke-dasharray: 262.62px;
  stroke-dashoffset: 262.62px;
  -webkit-animation: marquee-anim 2s ease forwards 0.21s;
          animation: marquee-anim 2s ease forwards 0.21s;
}

#marquee path:nth-child(8) {
  stroke-dasharray: 396.11px;
  stroke-dashoffset: 396.11px;
  -webkit-animation: marquee-anim 2s ease forwards 0.24s;
          animation: marquee-anim 2s ease forwards 0.24s;
}

#marquee path:nth-child(9) {
  stroke-dasharray: 209.44px;
  stroke-dashoffset: 209.44px;
  -webkit-animation: marquee-anim 2s ease forwards 0.27s;
          animation: marquee-anim 2s ease forwards 0.27s;
}

#marquee path:nth-child(10) {
  stroke-dasharray: 228.42px;
  stroke-dashoffset: 228.42px;
  -webkit-animation: marquee-anim 2s ease forwards 0.3s;
          animation: marquee-anim 2s ease forwards 0.3s;
}

#marquee path:nth-child(11) {
  stroke-dasharray: 351.77px;
  stroke-dashoffset: 351.77px;
  -webkit-animation: marquee-anim 2s ease forwards 0.33s;
          animation: marquee-anim 2s ease forwards 0.33s;
}

#marquee path:nth-child(12) {
  stroke-dasharray: 533.05px;
  stroke-dashoffset: 533.05px;
  -webkit-animation: marquee-anim 2s ease forwards 0.36s;
          animation: marquee-anim 2s ease forwards 0.36s;
}

#marquee path:nth-child(13) {
  stroke-dasharray: 210.38px;
  stroke-dashoffset: 210.38px;
  -webkit-animation: marquee-anim 2s ease forwards 0.39s;
          animation: marquee-anim 2s ease forwards 0.39s;
}

#marquee path:nth-child(14) {
  stroke-dasharray: 199.44px;
  stroke-dashoffset: 199.44px;
  -webkit-animation: marquee-anim 2s ease forwards 0.42s;
          animation: marquee-anim 2s ease forwards 0.42s;
}

#marquee path:nth-child(15) {
  stroke-dasharray: 92.29px;
  stroke-dashoffset: 92.29px;
  -webkit-animation: marquee-anim 2s ease forwards 0.45s;
          animation: marquee-anim 2s ease forwards 0.45s;
}

#marquee path:nth-child(16) {
  stroke-dasharray: 544.63px;
  stroke-dashoffset: 544.63px;
  -webkit-animation: marquee-anim 2s ease forwards 0.48s;
          animation: marquee-anim 2s ease forwards 0.48s;
}

#marquee path:nth-child(17) {
  stroke-dasharray: 357.23px;
  stroke-dashoffset: 357.23px;
  -webkit-animation: marquee-anim 2s ease forwards 0.51s;
          animation: marquee-anim 2s ease forwards 0.51s;
}

#marquee path:nth-child(18) {
  stroke-dasharray: 387.47px;
  stroke-dashoffset: 387.47px;
  -webkit-animation: marquee-anim 2s ease forwards 0.54s;
          animation: marquee-anim 2s ease forwards 0.54s;
}

#marquee path:nth-child(19) {
  stroke-dasharray: 331.79px;
  stroke-dashoffset: 331.79px;
  -webkit-animation: marquee-anim 2s ease forwards 0.57s;
          animation: marquee-anim 2s ease forwards 0.57s;
}

#marquee path:nth-child(20) {
  stroke-dasharray: 209.44px;
  stroke-dashoffset: 209.44px;
  -webkit-animation: marquee-anim 2s ease forwards 0.6s;
          animation: marquee-anim 2s ease forwards 0.6s;
}

#marquee path:nth-child(21) {
  stroke-dasharray: 331.8px;
  stroke-dashoffset: 331.8px;
  -webkit-animation: marquee-anim 2s ease forwards 0.63s;
          animation: marquee-anim 2s ease forwards 0.63s;
}

#marquee path:nth-child(22) {
  stroke-dasharray: 63.35px;
  stroke-dashoffset: 63.35px;
  -webkit-animation: marquee-anim 2s ease forwards 0.66s;
          animation: marquee-anim 2s ease forwards 0.66s;
}

#marquee path:nth-child(23) {
  stroke-dasharray: 1232px;
  stroke-dashoffset: 1232px;
  -webkit-animation: marquee-anim 2s ease forwards 0.69s;
          animation: marquee-anim 2s ease forwards 0.69s;
}

/****************************/

/* 
#marquee path:nth-child(22) {
  stroke-dasharray: 442.92px;
  stroke-dashoffset: 442.92px;
  animation: marquee-anim 2s ease forwards 0.5s;
}

#marquee path:nth-child(23) {
  stroke-dasharray: 228.42px;
  stroke-dashoffset: 228.42px;
  animation: marquee-anim 2s ease forwards 0.7s;
}

#marquee path:nth-child(24) {
  stroke-dasharray: 98.15px;
  stroke-dashoffset: 98.15px;
  animation: marquee-anim 2s ease forwards 0.9s;
}

#marquee path:nth-child(25) {
  stroke-dasharray: 390.74px;
  stroke-dashoffset: 390.74px;
  animation: marquee-anim 2s ease forwards 1.1s;
}

#marquee path:nth-child(26) {
  stroke-dasharray: 387.47px;
  stroke-dashoffset: 387.47px;
  animation: marquee-anim 2s ease forwards 1.3s;
}

#marquee path:nth-child(27) {
  stroke-dasharray: 215px;
  stroke-dashoffset: 215px;
  animation: marquee-anim 2s ease forwards 1.5s;
}

#marquee path:nth-child(28) {
  stroke-dasharray: 262.62px;
  stroke-dashoffset: 262.62px;
  animation: marquee-anim 2s ease forwards 1.7s;
}

#marquee path:nth-child(29) {
  stroke-dasharray: 396.11px;
  stroke-dashoffset: 396.11px;
  animation: marquee-anim 2s ease forwards 1.9s;
}

#marquee path:nth-child(30) {
  stroke-dasharray: 209.44px;
  stroke-dashoffset: 209.44px;
  animation: marquee-anim 2s ease forwards 2.1s;
}

#marquee path:nth-child(31) {
  stroke-dasharray: 228.42px;
  stroke-dashoffset: 228.42px;
  animation: marquee-anim 2s ease forwards 2.3s;
}

#marquee path:nth-child(32) {
  stroke-dasharray: 351.77px;
  stroke-dashoffset: 351.77px;
  animation: marquee-anim 2s ease forwards 2.5s;
}

#marquee path:nth-child(33) {
  stroke-dasharray: 533.05px;
  stroke-dashoffset: 533.05px;
  animation: marquee-anim 2s ease forwards 2.7s;
}

#marquee path:nth-child(34) {
  stroke-dasharray: 210.38px;
  stroke-dashoffset: 210.38px;
  animation: marquee-anim 2s ease forwards 2.9s;
}

#marquee path:nth-child(35) {
  stroke-dasharray: 199.44px;
  stroke-dashoffset: 199.44px;
  animation: marquee-anim 2s ease forwards 2.9s;
}

#marquee path:nth-child(36) {
  stroke-dasharray: 92.29px;
  stroke-dashoffset: 92.29px;
  animation: marquee-anim 2s ease forwards 2.9s;
}

#marquee path:nth-child(37) {
  stroke-dasharray: 544.63px;
  stroke-dashoffset: 544.63px;
  animation: marquee-anim 2s ease forwards 2.9s;
}

#marquee path:nth-child(38) {
  stroke-dasharray: 357.23px;
  stroke-dashoffset: 357.23px;
  animation: marquee-anim 2s ease forwards 2.9s;
}

#marquee path:nth-child(39) {
  stroke-dasharray: 387.47px;
  stroke-dashoffset: 387.47px;
  animation: marquee-anim 2s ease forwards 2.9s;
}

#marquee path:nth-child(40) {
  stroke-dasharray: 331.79px;
  stroke-dashoffset: 331.79px;
  animation: marquee-anim 2s ease forwards 2.9s;
}

#marquee path:nth-child(41) {
  stroke-dasharray: 209.44px;
  stroke-dashoffset: 209.44px;
  animation: marquee-anim 2s ease forwards 2.9s;
}

#marquee path:nth-child(42) {
  stroke-dasharray: 331.79px;
  stroke-dashoffset: 331.79px;
  animation: marquee-anim 2s ease forwards 2.9s;
}

#marquee path:nth-child(43) {
  stroke-dasharray: 63.35px;
  stroke-dashoffset: 63.35px;
  animation: marquee-anim 2s ease forwards 2.9s;
}

#marquee path:nth-child(44) {
  stroke-dasharray: 724px;
  stroke-dashoffset: 724px;
  animation: marquee-anim 2s ease forwards 2.9s;
} */

@-webkit-keyframes marquee-anim {
  to {
    stroke-dashoffset: 1;
  }
}

@keyframes marquee-anim {
  to {
    stroke-dashoffset: 1;
  }
}

@-webkit-keyframes fill {
  from {
    fill: transparent;
  }
  to {
    fill: #fff;
  }
}

@keyframes fill {
  from {
    fill: transparent;
  }
  to {
    fill: #fff;
  }
}

