/************* Marquee *************/
.marquee-container {
  width: 100%;
  position: relative;
}

/* .marquee-container::before {
    content: '';
    border-top: 8px solid var(--primary);
    width: 40%;
    position: absolute;
    left: 0;
    top: -50px;
  } */

#marquee {
  /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
  width: 700px;
  animation: fill 0.5s ease forwards 2.5s;
}

#marquee path:nth-child(1) {
  stroke-dasharray: 443px;
  stroke-dashoffset: 443px;
  animation: marquee-anim 2s ease forwards 0.5s;
}

#marquee path:nth-child(2) {
  stroke-dasharray: 228.42px;
  stroke-dashoffset: 228.42px;
  animation: marquee-anim 2s ease forwards 0.8s;
}

#marquee path:nth-child(3) {
  stroke-dasharray: 98.15px;
  stroke-dashoffset: 98.15px;
  animation: marquee-anim 2s ease forwards 0.1s;
}

#marquee path:nth-child(4) {
  stroke-dasharray: 390.74px;
  stroke-dashoffset: 390.74px;
  animation: marquee-anim 2s ease forwards 0.13s;
}

#marquee path:nth-child(5) {
  stroke-dasharray: 387.47px;
  stroke-dashoffset: 387.47px;
  animation: marquee-anim 2s ease forwards 0.16s;
}

#marquee path:nth-child(6) {
  stroke-dasharray: 215px;
  stroke-dashoffset: 215px;
  animation: marquee-anim 2s ease forwards 0.19s;
}

#marquee path:nth-child(7) {
  stroke-dasharray: 262.62px;
  stroke-dashoffset: 262.62px;
  animation: marquee-anim 2s ease forwards 0.21s;
}

#marquee path:nth-child(8) {
  stroke-dasharray: 396.11px;
  stroke-dashoffset: 396.11px;
  animation: marquee-anim 2s ease forwards 0.24s;
}

#marquee path:nth-child(9) {
  stroke-dasharray: 209.44px;
  stroke-dashoffset: 209.44px;
  animation: marquee-anim 2s ease forwards 0.27s;
}

#marquee path:nth-child(10) {
  stroke-dasharray: 228.42px;
  stroke-dashoffset: 228.42px;
  animation: marquee-anim 2s ease forwards 0.3s;
}

#marquee path:nth-child(11) {
  stroke-dasharray: 351.77px;
  stroke-dashoffset: 351.77px;
  animation: marquee-anim 2s ease forwards 0.33s;
}

#marquee path:nth-child(12) {
  stroke-dasharray: 533.05px;
  stroke-dashoffset: 533.05px;
  animation: marquee-anim 2s ease forwards 0.36s;
}

#marquee path:nth-child(13) {
  stroke-dasharray: 210.38px;
  stroke-dashoffset: 210.38px;
  animation: marquee-anim 2s ease forwards 0.39s;
}

#marquee path:nth-child(14) {
  stroke-dasharray: 199.44px;
  stroke-dashoffset: 199.44px;
  animation: marquee-anim 2s ease forwards 0.42s;
}

#marquee path:nth-child(15) {
  stroke-dasharray: 92.29px;
  stroke-dashoffset: 92.29px;
  animation: marquee-anim 2s ease forwards 0.45s;
}

#marquee path:nth-child(16) {
  stroke-dasharray: 544.63px;
  stroke-dashoffset: 544.63px;
  animation: marquee-anim 2s ease forwards 0.48s;
}

#marquee path:nth-child(17) {
  stroke-dasharray: 357.23px;
  stroke-dashoffset: 357.23px;
  animation: marquee-anim 2s ease forwards 0.51s;
}

#marquee path:nth-child(18) {
  stroke-dasharray: 387.47px;
  stroke-dashoffset: 387.47px;
  animation: marquee-anim 2s ease forwards 0.54s;
}

#marquee path:nth-child(19) {
  stroke-dasharray: 331.79px;
  stroke-dashoffset: 331.79px;
  animation: marquee-anim 2s ease forwards 0.57s;
}

#marquee path:nth-child(20) {
  stroke-dasharray: 209.44px;
  stroke-dashoffset: 209.44px;
  animation: marquee-anim 2s ease forwards 0.6s;
}

#marquee path:nth-child(21) {
  stroke-dasharray: 331.8px;
  stroke-dashoffset: 331.8px;
  animation: marquee-anim 2s ease forwards 0.63s;
}

#marquee path:nth-child(22) {
  stroke-dasharray: 63.35px;
  stroke-dashoffset: 63.35px;
  animation: marquee-anim 2s ease forwards 0.66s;
}

#marquee path:nth-child(23) {
  stroke-dasharray: 1232px;
  stroke-dashoffset: 1232px;
  animation: marquee-anim 2s ease forwards 0.69s;
}

/****************************/

/* 
#marquee path:nth-child(22) {
  stroke-dasharray: 442.92px;
  stroke-dashoffset: 442.92px;
  animation: marquee-anim 2s ease forwards 0.5s;
}

#marquee path:nth-child(23) {
  stroke-dasharray: 228.42px;
  stroke-dashoffset: 228.42px;
  animation: marquee-anim 2s ease forwards 0.7s;
}

#marquee path:nth-child(24) {
  stroke-dasharray: 98.15px;
  stroke-dashoffset: 98.15px;
  animation: marquee-anim 2s ease forwards 0.9s;
}

#marquee path:nth-child(25) {
  stroke-dasharray: 390.74px;
  stroke-dashoffset: 390.74px;
  animation: marquee-anim 2s ease forwards 1.1s;
}

#marquee path:nth-child(26) {
  stroke-dasharray: 387.47px;
  stroke-dashoffset: 387.47px;
  animation: marquee-anim 2s ease forwards 1.3s;
}

#marquee path:nth-child(27) {
  stroke-dasharray: 215px;
  stroke-dashoffset: 215px;
  animation: marquee-anim 2s ease forwards 1.5s;
}

#marquee path:nth-child(28) {
  stroke-dasharray: 262.62px;
  stroke-dashoffset: 262.62px;
  animation: marquee-anim 2s ease forwards 1.7s;
}

#marquee path:nth-child(29) {
  stroke-dasharray: 396.11px;
  stroke-dashoffset: 396.11px;
  animation: marquee-anim 2s ease forwards 1.9s;
}

#marquee path:nth-child(30) {
  stroke-dasharray: 209.44px;
  stroke-dashoffset: 209.44px;
  animation: marquee-anim 2s ease forwards 2.1s;
}

#marquee path:nth-child(31) {
  stroke-dasharray: 228.42px;
  stroke-dashoffset: 228.42px;
  animation: marquee-anim 2s ease forwards 2.3s;
}

#marquee path:nth-child(32) {
  stroke-dasharray: 351.77px;
  stroke-dashoffset: 351.77px;
  animation: marquee-anim 2s ease forwards 2.5s;
}

#marquee path:nth-child(33) {
  stroke-dasharray: 533.05px;
  stroke-dashoffset: 533.05px;
  animation: marquee-anim 2s ease forwards 2.7s;
}

#marquee path:nth-child(34) {
  stroke-dasharray: 210.38px;
  stroke-dashoffset: 210.38px;
  animation: marquee-anim 2s ease forwards 2.9s;
}

#marquee path:nth-child(35) {
  stroke-dasharray: 199.44px;
  stroke-dashoffset: 199.44px;
  animation: marquee-anim 2s ease forwards 2.9s;
}

#marquee path:nth-child(36) {
  stroke-dasharray: 92.29px;
  stroke-dashoffset: 92.29px;
  animation: marquee-anim 2s ease forwards 2.9s;
}

#marquee path:nth-child(37) {
  stroke-dasharray: 544.63px;
  stroke-dashoffset: 544.63px;
  animation: marquee-anim 2s ease forwards 2.9s;
}

#marquee path:nth-child(38) {
  stroke-dasharray: 357.23px;
  stroke-dashoffset: 357.23px;
  animation: marquee-anim 2s ease forwards 2.9s;
}

#marquee path:nth-child(39) {
  stroke-dasharray: 387.47px;
  stroke-dashoffset: 387.47px;
  animation: marquee-anim 2s ease forwards 2.9s;
}

#marquee path:nth-child(40) {
  stroke-dasharray: 331.79px;
  stroke-dashoffset: 331.79px;
  animation: marquee-anim 2s ease forwards 2.9s;
}

#marquee path:nth-child(41) {
  stroke-dasharray: 209.44px;
  stroke-dashoffset: 209.44px;
  animation: marquee-anim 2s ease forwards 2.9s;
}

#marquee path:nth-child(42) {
  stroke-dasharray: 331.79px;
  stroke-dashoffset: 331.79px;
  animation: marquee-anim 2s ease forwards 2.9s;
}

#marquee path:nth-child(43) {
  stroke-dasharray: 63.35px;
  stroke-dashoffset: 63.35px;
  animation: marquee-anim 2s ease forwards 2.9s;
}

#marquee path:nth-child(44) {
  stroke-dasharray: 724px;
  stroke-dashoffset: 724px;
  animation: marquee-anim 2s ease forwards 2.9s;
} */

@keyframes marquee-anim {
  to {
    stroke-dashoffset: 1;
  }
}

@keyframes fill {
  from {
    fill: transparent;
  }
  to {
    fill: #fff;
  }
}
